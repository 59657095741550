<template>
	<div id="Recharge">
		<div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
			兑换记录
		</div>
		<div class="rechange-list">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div class="item-top">
					{{ item.created_at }}
					<div class="right">
						积分消耗
						<div class="coins"></div>
						<span>{{ item.integral }}</span>
					</div>
				</div>
				<div class="gun-info">
					<div class="num">x{{ item.num }}</div>
					<div class="img-con" :class="['lv'+item.lv]">
						<img :src="item.cover" alt="">
					</div>
					<div class="name">{{ item.name }}</div>
					<div class="price">
						<div class="coins"></div>
						{{ item.bean }}
					</div>
				</div>
			</div>
		</div>
		<div class="page-con" style="margin-top: 12px;">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		ShopRecords
	} from '@/network/api.js'
	export default {
		name: 'MallRecord',
		data() {
			return {
				active: 0, //tab分栏
				list: [], 

				total:0,//总数据数量
				per_page:10,//一页数据
				page_size:1,//页码
			}
		},
		created() {
			this.ShopRecords()
		},
		methods: {
			ShopRecords() {
				this.list = []
				ShopRecords(this.page_size).then((res) => {
					this.total = res.data.data.total
					// this.per_page = res.data.data.per_page
					this.list = res.data.data.records
				})
			},
			handleCurrentChange(val) {
				this.page_size = val
				this.ShopRecords()
			}
		},

		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			}
		}

	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
	.rechange-list{
		.item{
			margin: 10px auto 0;
			width: 343px;
			.item-top{
				background: url(~@/assets/img/rechange-title-bg.png);
				background-size: 100% 100%;
				height: 40px;
				@include Scenter;
				padding: 0 16px;
				font-size: 12px;
				color: #999;
				.right{
					margin-left: auto;
					@include Scenter;
					color: #fff;
					.coins{
						background-image: url(~@/assets/img/coins-integral.svg);
						width: 14px;
						height: 14px;
						margin: 0 4px;
					}
					span{
						background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
			.gun-info{
				height: 168px;
				@include HScenter;
				flex-direction: column;
				background: #1D1D28;
				position: relative;
				.num{
					position: absolute;
					right: 10px;
					top: 10px;
					// color: #FFB800;
					font-size: 18px;
				}
				.img-con{
					width: 124px;
					height: 86px;
					background-size: auto 100%;
					background-position: center !important;
					background-repeat: no-repeat !important;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
				.name{
					@include wordhide;
					margin: 6px 0;
				}
				.price{
					.coins{
						background-image: url(~@/assets/img/coins-yellow.svg);
					}
				}
			}
		}
	}
</style>
